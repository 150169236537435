// ===== SUMARIO DE ESTILOS ====
// 01 - telas que possuem grid
// 02 - tela de dashboard
// 03 - tela not found
// 04 - componentes globais 


// ====== 01 TELAS QUE EXIBEM GRID ======

.form-ext {
  height: 100%;
}

mat-tab-group{
  border-radius: 4px !important;
}

.pesq-info {
  height: auto;
  border-radius: 1.8vh;
}

.title-pesq {
  padding-top: 15px;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
  justify-content: center;
  display: flex;
  color: #267f99;
  font-size: large;
  font-weight: bold;
}

.img-title-pesq {
  width: 20px;
  height: 20px;
  padding-right: 5px;
}

.novapesq-form {
  width: 100%;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  max-width: -webkit-fill-available;
}

.separador {
  height: 0.1vh;
}

.pesq-re {
  height: auto;
  padding: 0;
  border-radius: 1.8vh 1.8vh 0vh 0vh;
}

.header-pesq {
  height: 18%;
}

.resultado {
  height: 76%;
}

.pesq-table {
  overflow-y: auto;
  overflow-x: auto;
  height: calc(100% - 5px);
  width: 98%;
  margin-top: 70px;
}

mat-card {
  box-shadow: none !important;
}

.col-mail {
  flex-direction: row;
  display: flex;
  align-items: center;
}

.outer-wrapper {
  margin: 10px;
  border-radius: 4px;
  max-height: fit-content;
  margin-top: -4rem;

}

.table-wrapper {
  height: fit-content;
  max-height: 53.4vh;
  margin-top: 22px;
  margin: 15px;
  padding-bottom: 20px;
  margin-top: 79px;
}


.table-grid {
  min-width: max-content;
  border-collapse: separate;
  border-spacing: 0px 12px;
  width: 100%;
}

.table-grid th {
  position: sticky;
  top: 0px;
  z-index: 3;
  background-color: #ffffff;
  border-bottom: 1px solid #c8c8c8;
  color: #767675;
  text-align: left;
  font-weight: normal;
  font-size: 13px;
  min-width: 80px;
}

.table-grid th:last-child {
  padding: 0;
  text-align: center;
}

.table-grid th,
.table-grid td {
  padding: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.table-grid th:last-child,
.table-grid td:last-child {
  padding: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.table-grid td {
  text-align: left;
  background-color: #f0f0f0;
  color: #787c83;
  font-weight: normal;
  font-size: 1.3vh;
}

.mw-grid {
 max-width: 8vw;
}

.table-grid td:first-child+td+td {
  text-align: left;
}

.table-grid td:first-child+td {
  max-width: 170px;
}

.table-grid td:first-child {
  border-radius: 15px 0 0 15px;
  padding: 15px;
}

.table-grid td:last-child {
  border-radius: 0 15px 15px 0;
  border-right: none;
  padding-left: 0 !important;
}

.iconTop {
  font-size: 10px;
  line-height: 35px;
}

.ul-table {
  line-height: 10%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  list-style: none;
  margin: 0 0 0 -30px;
}

.li-table {
  cursor: pointer;
  padding: 2px;
  border-radius: 10px;
}

.li-table button {
  border: none;
}

.icon-table:hover {
  filter: invert(47%) sepia(86%) saturate(2024%) hue-rotate(162deg) brightness(98%) contrast(90%) !important;
}

.border-column {
  vertical-align: middle;
  border-right: 2px solid #ffffff;
  padding-right: 20px;
  height: auto;
}

@keyframes blink {
  50% {
    color: transparent
  }
}

.loader__dot {
  animation: 1s blink infinite
}

.loader__dot:nth-child(2) {
  animation-delay: 250ms
}

.loader__dot:nth-child(3) {
  animation-delay: 500ms
}

.dot-space {
  margin-top: -12px;
  font-size: 15px;
  font-weight: bold;
  margin-left: 6px;
}

@media only screen and (max-height: 950px) {
  .header-pesq {
    height: 22%;
  }

  .resultado {
    height: 76%;
  }
}

@media only screen and (max-height: 850px) {
  .header-pesq {
    height: 25%;
  }

  .resultado {
    height: 73%;
  }
}

@media only screen and (max-height: 700px) {
  .header-pesq {
    height: 28%;
  }

  .resultado {
    height: 70%;
  }
}

// @media screen and (min-width: 1199px) and (max-width: 1581px){
//   .resultado{
//     margin-top: 70px !important;
//   }
// }



@media (max-width: 1399px) {
  .pesq-re {
    height: auto;
  }
}

@media only screen and (max-width: 465px) {
  .col-mail {
    flex-direction: column !important;
  }
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #B3B3B3;
  border-radius: 5px;
  background-clip: content-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.gs-botaoAzulPadrao {
  color: white !important;
  text-align: center;
  width: auto;
  height: 44px;
  background-color: #00ccff !important;
  border-radius: 5px;
  margin-left: 5px;
  cursor: pointer !important;
}

.gs-botaoRedPadrao {
  color: white !important;
  text-align: center;
  width: auto;
  height: 44px;
  background-color: #db0707 !important;
  border-radius: 5px;
  margin-left: 5px;
  cursor: pointer !important;
}

.gs-botaoAzul {
  background-color: #00ccff;
  border: none;
  width: 250px;
  padding: 6px 30px;
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
}

.gs-botaoAzul:hover {
  background-color: #0da6d9 !important;
}


mat-fab {
  padding: 7px 0 !important;
}


.mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

// =========================


// ====== 02 DASHBOARD ==========
.table-dashboard {
  border-collapse: collapse !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
  table-layout: fixed !important;
  background-color: transparent;
}

.table-dashboard tr {
  padding: .35em;
}

.table-dashboard td {
  border-right: 2px solid #c8c8c8e3;
  height: 60px;
  padding: .35em;
  background-color: transparent !important;
}

.table-dashboard td:last-child {
  border: none !important;
}

.table-dashboard .th-dashboard,
.table-dashboard .td-dashboard {
  padding-bottom: .625em;
  text-align: center;
}

.table-dashboard .th-dashboard {
  font-size: 1.5vh !important;
  color: #8f9597 !important;
  font-weight: normal;
  border-bottom: none !important;
}

.grafico-1 {
  width: 30%;
  height: 100%;
  border: 2px solid #0DA6D9;
  border-radius: 15px;
  text-align: center;
  font-size: 2.5vw;
  color: #0DA6D9;
}

.grafico-2 {
  width: 60%;
  height: 100%;
  margin-left: 20px;
  border: 2px solid #0DA6D9;
  border-radius: 15px;
  text-align: center;
  font-size: 2.5vh;
  color: #0DA6D9;
}

.grafico p {
  height: 8%;
  padding: 0;
  margin: 0;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 2.5vh;
  color: #33a3d9;
}

.td-dashboard {
  font-size: 2.2vh;
  color: #0DA6D9;
  font-weight: bold;
}

#chart {
  max-width: auto;
  margin: 35px auto;
  opacity: 0.9;
  border: solid 1px #0b83ab;
  border-radius: 14px;
  padding: 8px;
  min-height: 415px;
}

#chart2 {
  max-width: auto;
  margin: 35px auto;
  opacity: 0.9;
  border: solid 1px #0b83ab;
  border-radius: 14px;
  padding: 8px;
  min-height: 400.7px !important;
  // height: 416px;
}

// ====mobile

.table-dashboard-mobile {
  border-collapse: collapse !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
  table-layout: fixed !important;
  background-color: transparent;
  display: none;
}

.table-dashboard-mobile tr {
  padding: .35em;
}

.table-dashboard-mobile td {
  border-right: 1px solid #c8c8c8e3;
  height: 60px;
  padding: .35em;
  background-color: transparent !important;
}

.table-dashboard-mobile td:last-child {
  border: none !important;
}

.table-dashboard-mobile .th-dashboard-mobile,
.table-dashboard-mobile .td-dashboard-mobile {
  text-align: center;
}

.table-dashboard-mobile .th-dashboard-mobile {
  font-size: 1.5vh !important;
  color: #75797b !important;
  font-weight: normal;
  border-bottom: none !important;
}

.td-dashboard-mobile {
  font-size: 1.6vh;
  color: #0DA6D9;
  font-weight: bold;
  word-break: break-word;
}

//======================================


//======== 03 TELA NOT FOUND ===========

.background-not-found {
  height: 100vh;
  display: flex;
  display: -webkit-flex;
  /* Garante compatibilidade com navegador Safari. */
  vertical-align: middle;
  justify-content: center;
  align-items: center;
}

.title-not-found {
  margin-top: 20px;
  font-size: 30px;
  color: #b1b0b0;
  font-weight: 550;
}

@media (max-width: 450px) {
  .title-not-found {
    font-size: 20px;
  }

  .img-not-found {
    width: 90%;
  }
}

//========================


//======== 04 COMPONENTES GLOBAIS ================
.btn-azul {
  color: white !important;
  text-align: center;
  background-color: #00ccff !important;
  padding: 5px 40px;
}

.btn-azul:hover {
  background-color: #0da6d9 !important;
}

@media (max-width: 820px) {
  .btn-azul {
    margin-top: 10px;
    width: 235px;
  }
}


.mat-elevation-z8 {
  box-shadow: none;
}

.mat-drawer-container {
  background-color: #ccc;
}

.text-left {
  text-align: left !important;
}

.form-control:focus {
  border-color: 1px solid #a7a7a7 !important;
}




.mat-chip-selected {
  border-radius: 15px;
}

/* Estilo para exibir chips lado a lado */
.rounded-chip {
  border-radius: 50px; /* Valor alto para bordas totalmente arredondadas */
  text-align: center;
  padding: 3px 3px 3px 10px;
}

/* Estilo para exibir chips lado a lado */
.chip-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px; /* Ajuste o espaçamento conforme necessário */
}

.mat-chip.mat-standard-chip {
  background-color: #5c90a7 !important;
  color: #ffffff !important;
}

.trashIcon{
  color: rgb(4, 72, 124);
}