/* You can add global styles to this file, and also import other style files */
@import '@angular/material/theming';
@import 'global-styles.scss';
@import '~bootstrap/dist/css/bootstrap.min.css';
@include mat-core();

$custom-app-primary: mat-palette($mat-light-blue, 900);
$custom-app-secondary: mat-palette($mat-light-blue, 700, 800, 900);
$custom-app-warn: mat-palette($mat-gray, 100);

$custom-theme: mat-light-theme($custom-app-primary, $custom-app-secondary, $custom-app-warn);

@include angular-material-theme($custom-theme);

html, body { height: 100vh !important; font-family: Arial, Helvetica, sans-serif }
body { margin: 0;  background-color: #cccccc ; font-family: Arial, Helvetica, sans-serif, "Helvetica Neue",  }

.container-nav{
    margin-left: auto !important;
}

input .form-control:focus{
    border-color: 1px solid #a7a7a7 !important;
  }

.mat-snack-bar-container{
    background-color: #4aa3c0;
}

.error.mat-snack-bar-container{
    background-color: #4aa3c0;
}

.mat-dialog-container {
    border-radius: 11px !important;
  }

